export const Images = ({
  Image1,
  Image1FileName,
  Image2,
  Image2FileName,
  Image3,
  Image3FileName,
  Image4,
  Image4FileName,
}) => {
  // return `data:image/${ext(Image1FileName)};base64, ${Image1}`;

  return [
    Image1
      ? `data:image/${getFileExt(Image1FileName)};base64, ${Image1}`
      : null,
    Image2
      ? `data:image/${getFileExt(Image2FileName)};base64, ${Image2}`
      : null,
    Image3
      ? `data:image/${getFileExt(Image3FileName)};base64, ${Image3}`
      : null,
    Image4
      ? `data:image/${getFileExt(Image4FileName)};base64, ${Image4}`
      : null,
  ];
};

export function getFileExt(filename) {
  return (
    filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
    filename
  );
}

// Capitalizes first letter of each word in a sentence.
export function capitalize(string) {
  let arr = string.split(" ");
  let finalString = "";
  arr.forEach((e) => {
    finalString += e.charAt(0).toUpperCase() + e.slice(1) + " ";
  });
  return finalString;
}
