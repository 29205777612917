export const brand_data = [
  {
    displayName: "Empirical Labs",
    Name: "ELI",
    SecondaryName: "ELI-Digital",
    url: "empirical",
    logo: "/brands/eli/eli-logo.png",
    logo_main: "/brands/eli/eli-logo-main.svg",
    website: "https://www.empiricallabs.com/",
    short_desc:
      "Products that work a little easier, a little better, and a lot longer.",
    long_desc:
      "ELI was started in 1988 and functioned mainly as a recording studio and electronic consulting firm. The founder, Dave Derr, was also employed at the time by Eventide as an audio engineer where he was part of the core design team for the H3000, H3500, and DSP4000. His experience at Eventide proved invaluable for his later work. While working alongside Ken Bogdanowicz and Bob Belcher (both now at Soundtoys), Dave was exposed to state of the art engineering and the modern world of digital signal processing (DSP). The first Empirical Labs product – the Distressor – was designed in 1994, tested and revised for several years, and released in 1996. With over twenty-five thousand units sold, it has unquestionably become a modern classic. In 1998, Empirical Labs closed the doors of the recording studio to focus on the growing signal processing business. Today, all Empirical Labs products are still manufactured in the USA and rigorously calibrated and tested by hand.",
  },
  {
    displayName: "Whitestone Audio",
    Name: "Whitestone Audio (V)",
    url: "whitestone",
    logo: "/brands/whitestone/logo.png",
    logo_main: "/brands/whitestone/logo-main.png",
    website: "https://www.whitestoneaudio.com/",
    short_desc: "The Whitestone Audio P331 Tube Loading Amplifier.",
    long_desc:
      "Whitestone Audio Instruments is the brainchild of Dave Rosen and his multi-Grammy® nominated mastering engineer wife Kim Rosen. Much of the way Kim works is exploiting the sound of the input and output gain stages of some of her favorite equalizers and compressors with actual processing bypassed. Just the subtle enhancement some of these analog gain circuits provide often times is just the thing to add a bit of “magic”. The goal for the P331 was to capture this kind of enhancement potential in a line amplifier that delivers the natural clarity of a well-designed tube circuit. At the same time, implementing fully switched controls that introduce harmonics and other carefully implemented, and repeatable, non-linearities to subtly enhance the signal without harming the recording’s original intent.",
  },
  {
    displayName: "Serpent Audio",
    Name: "Serpent Audio LLC.",
    url: "serpent",
    logo: "/brands/serpent/logo.png",
    logo_main: "/brands/serpent/logo-main.png",
    website: "https://serpentaudio.com/",
    short_desc: "Taking compression to a whole new level.",
    long_desc:
      "Serpent Audio was founded by Mike Pildis in 2010. With an expertise in vintage processing gear, Pildis found ways to recreate the tonal characteristics of these classic units while also improving the feature sets. All units are made in the USA to an exceptional level of craft and workmanship.",
  },
  {
    displayName: "Useful Arts Audio",
    Name: "Useful Arts",
    url: "usefularts",
    logo: "/brands/useful/ua_logo_sm.png",
    logo_main: "/brands/useful/logo-main.png",
    website: "https://www.usefulartsaudio.com/",
    short_desc:
      "An aesthetic quest for vibrant, living sound straight from the source.",
    long_desc:
      "Useful Arts was born out of an aesthetic quest for vibrant, living sound straight from the source — not merely a technically clean sound that needs enhancement after enhancement to speak in a mix. Back in the days when the U-47 was a commonly-used “standard” microphone, there were no “standard” preamps — studios used hand-built consoles with custom tube preamps designed to capture the personality of the recording. In the same way, our hand-built products are designed to enhance dynamics and perceived tonal richness, not simply to make a small signal electrically bigger. We invite you to hear for yourself the difference that philosophy makes.",
  },
  {
    displayName: "Strauss Elektroakustik",
    Name: "STRAUSS  Elektroakustik",
    url: "strauss",
    color: "bg-gray-200",
    text_color: "text-black",
    logo: "/brands/se/logo.png",
    logo_main: "/brands/se/logo-main.png",
    website: "http://www.strauss-electroacoustics.com/",
    short_desc: "For the purest High End Mastering and audiophile listening.",
    long_desc:
      "After 20 years of continuous production of the Midfield SE-MF-2 Mastering Studio Monitor we developed the LIT electrodynamic transducer as the next step forward in audio reproduction quality. The Low Inductivity Technology LIT consists of a new designed motor, smaller moving masses and reduced mechanical resistance. In consequence resolution of the reproduction is improved and very low harmonic and non-harmonic distortions are achieved.",
  },
  {
    displayName: "UBK Fatso",
    Name: "Wave Distro",
    url: "ubk",
    logo: "/brands/ubk_wave-logo.jpg",
    short_desc: "The Wave Distro UBK EL7 Fatso",
    long_desc:
      "Originally developed in 2008 with Gregory Scott [UBK] of Kush Audio, the UBK Fatso is now produced by Wave Distro in conjunction with Empirical Labs. The UBK Fatso has become a favorite tool among producers and engineers looking for character and colour in their compression.",
  },
  {
    displayName: "Kicktone",
    Name: "Kicktone Mirophones",
    url: "kicktone",
    logo: "/brands/kicktone/insta.jpeg",
    website: "https://www.kicktonemic.com/",
    short_desc: "Multi-Purpose Dynamic Full-Range Microphone",
    long_desc:
      "Meet the Kicktone from Morton Microphone Systems. A revolutionary mic that will change the way you record and amplify low-end sounds. Designed for kick drums, used by everyone who needs to capture a robust, dynamic sound. Voted Best Product of the year '21!",
  },
  {
    displayName: "Quagliardi Pro Audio",
    Name: "Quagliardi Pro Audio",
    url: "quagliardi",
    logo: "/brands/quag/icon.png",
    website: "https://www.quagliardiaudio.com/",
    short_desc:
      "Crafting audio experiences.",
    long_desc:
      "Quagliardi Pro Audio is an Argentina based company dedicated to the design and manufacture of high-end audio equipment for studio and stage. Founded in 2020 by Tomás Quagliardi, it already has a range of products in its catalog that have found their place in iconic studios within the Argentine music scene.",
  },
];
