import { Transition, Menu } from "@headlessui/react";
import { Fragment } from "react";
import Link from "next/link";

//Hooks
import { useAuth } from "../../../lib/hooks/auth";

const UserMenu = () => {
  const { signout, userData } = useAuth();

  return (
    <div className="relative inline-block text-left">
      <Menu>
        {({ open }) => (
          <Fragment>
            <Menu.Button
              type="button"
              id="user-menu"
              aria-expanded="false"
              aria-haspopup="true"
              className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-primary focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-primaryDark focus:ring-primaryDark"
            >
              <span className="sr-only">Open user menu</span>
              <span className="font-medium leading-none text-white">
                {userData && userData.contact.FirstName.charAt(0).toUpperCase()}
                {userData && userData.contact.LastName.charAt(0).toUpperCase()}
              </span>
            </Menu.Button>
            <Transition
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Menu.Items
                static
                className="z-20 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu"
              >
                <div className="px-4 py-3" role="none">
                  <p className="text-sm" role="none">
                    Signed in as
                  </p>
                  <p
                    className="text-sm font-medium text-gray-900 truncate"
                    role="none"
                  >
                    {userData && userData.contact.FullName}
                  </p>
                </div>
                <Menu.Item>
                  {({ active }) => (
                    <Link href="/dashboard">
                      <span
                        className={`group flex items-center px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 ${
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                        }`}
                        role="menuitem"
                      >
                        <svg
                          className={`mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 ${
                            active ? "text-gray-500" : "text-gray-400"
                          }`}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                        </svg>
                        Dashboard
                      </span>
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => signout()}
                      className={`group flex items-center px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 hover:cursor-pointer ${
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                      }`}
                      role="menuitem"
                    >
                      <svg
                        className={`mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 ${
                          active ? "text-gray-500" : "text-gray-400"
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Sign out
                    </div>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Fragment>
        )}
      </Menu>
    </div>
  );
};

export default UserMenu;
