//Modules
import { Fragment, useState } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import Link from "next/link";
import { MenuIcon, ShoppingBagIcon, XIcon } from "@heroicons/react/outline";
import { useFormState } from "react-hook-form";

//Data
import { brand_data as brandData } from "../../utils/data/brands";
import { equipment_data as equipData } from "../../utils/data/equipment";

//Hooks
import { useAuth } from "../../lib/hooks/auth";
import { useCart } from "../../lib/hooks/cart";

//Components
import UserMenu from "./usermenu/usermenu";

const navigation = {
  categories: [
    {
      id: "brands",
      name: "Brands",
      featured: [
        {
          name: "Kicktone Microphone",
          href: "/kicktone",
          imageSrc: "/features/kicktone.png",
          imageAlt: "Kicktone Microphone",
        },
        {
          name: "Whitestone - P331 EVL",
          href: "/WAI-P331-EVL",
          imageSrc: "/features/whitestone_evl.jpeg",
          imageAlt: "Whitestone - P331 EVL",
        },
      ],
    },
    {
      id: "equipment",
      name: "Equipment",
      featured: [
        {
          name: "ELI BIG-FrEQ",
          href: "/big-freq",
          imageSrc: "/features/big-freq.jpg",
          imageAlt: "ELI BIG-FrEQ.",
        },
        {
          name: "Strauss Nearfield Monitor",
          href: "/SE-NF-3",
          imageSrc: "/features/strauss.jpeg",
          imageAlt: "Strauss NF-3",
        },
      ],
    },
  ],
  pages: [
    { name: "Company", href: "/company" },
    { name: "Contact", href: "/contact" },
  ],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = () => {
  const [open, setOpen] = useState(false);
  const { authObject } = useAuth();
  const { setCartOpen, cart } = useCart();

  return (
    <div className="bg-white">
      {/* ////////////////////////////////////////////////////////////////////// */}
      {/* MOBILE HEADER START */}
      {/* ////////////////////////////////////////////////////////////////////// */}

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
              <div className="px-4 pt-5 pb-2 flex">
                <button
                  type="button"
                  className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                  onClick={() => setOpen(false)}
                >
                  <div className="sr-only">Close menu</div>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Links */}
              <Tab.Group as="div" className="mt-2">
                <div className="border-b border-gray-200">
                  <Tab.List className="-mb-px flex px-4 space-x-8">
                    {navigation.categories.map((category) => (
                      <Tab
                        key={category.name}
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? "text-indigo-600 border-indigo-600"
                              : "text-gray-900 border-transparent",
                            "flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-base font-medium"
                          )
                        }
                      >
                        {category.name}
                      </Tab>
                    ))}
                  </Tab.List>
                </div>
                <Tab.Panels as={Fragment}>
                  {navigation.categories.map((category) => (
                    <Tab.Panel
                      key={category.name}
                      className="pt-10 pb-8 px-4 space-y-10"
                    >
                      {category.id === "brands" && (
                        <Fragment>
                          <div className="grid grid-cols-2 gap-x-4">
                            {category.featured.map((item) => (
                              <div
                                key={item.name}
                                className="group relative text-sm"
                              >
                                <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden group-hover:opacity-75">
                                  <img
                                    src={item.imageSrc}
                                    alt={item.imageAlt}
                                    className="object-center object-cover"
                                  />
                                </div>
                                <Link href={item.href}>
                                  <span className="mt-6 block font-medium text-gray-900">
                                    <span
                                      className="absolute z-10 inset-0"
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </span>
                                </Link>
                                <p aria-hidden="true" className="mt-1">
                                  {item.text}
                                </p>
                              </div>
                            ))}
                          </div>
                          {brandData.map((brand, i) => (
                            <Link href={"/brands/" + brand.url} key={i}>
                              <li className="flex  items-center hover:cursor-pointer hover:bg-gray-100 rounded-tr-lg rounded-br-lg">
                                <div className="flex-shrink-0 rounded-lg inline-flex bg-gray-100 p-2 ring-4 ring-white">
                                  <img
                                    className="h-6 w-6"
                                    src={brand.logo}
                                    alt=""
                                  />
                                </div>
                                <div className="flex-1 min-w-0 ml-4">
                                  <p className="text-sm font-medium text-gray-900">
                                    {brand.displayName}
                                  </p>
                                </div>
                              </li>
                            </Link>
                          ))}
                        </Fragment>
                      )}
                      {category.id === "equipment" && (
                        <Fragment>
                          <div className="grid grid-cols-2 gap-x-4">
                            {category.featured.map((item) => (
                              <div
                                key={item.name}
                                className="group relative text-sm"
                              >
                                <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden group-hover:opacity-75">
                                  <img
                                    src={item.imageSrc}
                                    alt={item.imageAlt}
                                    className="object-center object-cover"
                                  />
                                </div>
                                <Link href={item.href}>
                                  <span className="mt-6 block font-medium text-gray-900">
                                    <span
                                      className="absolute z-10 inset-0"
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </span>
                                </Link>
                                <p aria-hidden="true" className="mt-1">
                                  {item.text}
                                </p>
                              </div>
                            ))}
                          </div>
                          <div className="relative rounded-lg bg-white flex items-center space-x-3 max-w-full">
                            <ul
                              role="list"
                              aria-labelledby={`brand-heading`}
                              className="space-y-6 sm:space-y-4 w-full"
                            >
                              {equipData.map((item, i) => (
                                <li className="flow-root" key={i}>
                                  <Link href={item.href}>
                                    <span className="min-w-full -m-3 p-3 flex items-center space-x-4 rounded-md text-base leading-6 font-medium text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150">
                                      {item.icon && (
                                        <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" />
                                      )}
                                      {item.svg && item.svg}
                                      <span>{item.name}</span>
                                    </span>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </Fragment>
                      )}
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>

              <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                {navigation.pages.map((page) => (
                  <div key={page.name} className="flow-root">
                    <a
                      href={page.href}
                      className="-m-2 p-2 block font-medium text-gray-900"
                    >
                      {page.name}
                    </a>
                  </div>
                ))}
              </div>

              <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                <div className="flow-root">
                  <Link href="/signin">
                    <span className="-m-2 p-2 block font-medium text-gray-900">
                      Sign in
                    </span>
                  </Link>
                </div>
                <div className="flow-root">
                  <Link href="/signup">
                    <span className="-m-2 p-2 block font-medium text-gray-900">
                      Create account
                    </span>
                  </Link>
                </div>
              </div>

              {/* <div className="border-t border-gray-200 py-6 px-4">
                <a href="#" className="-m-2 p-2 flex items-center">
                  <img
                    src="https://tailwindui.com/img/flags/flag-canada.svg"
                    alt=""
                    className="w-5 h-auto block flex-shrink-0"
                  />
                  <span className="ml-3 block text-base font-medium text-gray-900">
                    CAD
                  </span>
                  <span className="sr-only">, change currency</span>
                </a>
              </div> */}
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      {/* ////////////////////////////////////////////////////////////////////// */}
      {/* DESKTOP HEADER START */}
      {/* ////////////////////////////////////////////////////////////////////// */}

      <header className="relative bg-white">
        {/* <p className="bg-primary h-10 flex items-center justify-center text-sm font-medium text-white px-4 sm:px-6 lg:px-8">
          Free freight on all orders within the US placed via this portal
          through August 5th 2022!
        </p> */}

        <nav
          aria-label="Top"
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
        >
          <div className="border-b border-gray-200">
            <div className="h-16 flex items-center">
              <button
                type="button"
                className="bg-white p-2 rounded-md text-gray-400 lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Logo */}
              <div className="ml-4 flex lg:ml-0">
                <Link href="/">
                  <div>
                    <span className="sr-only">Wave-Distro</span>
                    <img
                      className="h-10 w-auto rounded-lg"
                      src="/logo/wave-logo-icon.png"
                      alt="wave-distro logo"
                    />
                  </div>
                </Link>
              </div>

              {/* Flyout menus */}
              <Popover.Group className="hidden lg:ml-8 lg:block lg:self-stretch">
                <div className="h-full flex space-x-8">
                  {navigation.categories.map((category) => (
                    <Popover key={category.name} className="flex">
                      {({ open }) => (
                        <>
                          <div className="relative flex">
                            <Popover.Button
                              className={classNames(
                                open
                                  ? "border-indigo-600 text-indigo-600"
                                  : "border-transparent text-gray-700 hover:text-gray-800",
                                "relative z-10 flex items-center transition-colors ease-out duration-200 text-sm font-medium border-b-2 -mb-px pt-px"
                              )}
                            >
                              {category.name}
                            </Popover.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Popover.Panel className="absolute top-full inset-x-0 text-sm text-gray-500 z-40">
                              {({ close }) => (
                                <Fragment>
                                  {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                                  <div
                                    className="absolute inset-0 top-1/2 bg-white shadow"
                                    aria-hidden="true"
                                  />
                                  {/* BRANDS */}
                                  {category.id === "brands" && (
                                    <div className="relative bg-white">
                                      <div className="max-w-7xl mx-auto px-8">
                                        <div className="grid grid-cols-2 gap-y-10 gap-x-8 py-16">
                                          <div className="col-start-2 grid grid-cols-2 gap-x-8">
                                            {category.featured.map((item) => (
                                              <div
                                                key={item.name}
                                                className="group relative text-base sm:text-sm"
                                              >
                                                <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden group-hover:opacity-75">
                                                  <img
                                                    src={item.imageSrc}
                                                    alt={item.imageAlt}
                                                    className="object-center object-cover"
                                                  />
                                                </div>
                                                <Link href={item.href}>
                                                  <div className="mt-6 block font-medium text-gray-900">
                                                    <span
                                                      className="absolute z-10 inset-0"
                                                      aria-hidden="true"
                                                    />
                                                    {item.name}
                                                  </div>
                                                </Link>
                                                <p
                                                  aria-hidden="true"
                                                  className="mt-1"
                                                >
                                                  Shop now
                                                </p>
                                              </div>
                                            ))}
                                          </div>
                                          <div className="row-start-1 grid grid-cols-2 gap-y-10 gap-x-8 text-sm truncate">
                                            <div className="relative rounded-lg bg-white px-6 shadow-sm flex items-center space-x-3 max-w-full">
                                              <ul
                                                role="list"
                                                aria-labelledby={`brand-heading`}
                                                className="space-y-6 sm:space-y-4"
                                              >
                                                {brandData.map((brand, i) => (
                                                  <Link
                                                    href={
                                                      "/brands/" + brand.url
                                                    }
                                                    key={i}
                                                  >
                                                    <li
                                                      onClick={() => {
                                                        close();
                                                      }}
                                                      className="flex items-center hover:cursor-pointer hover:bg-gray-100 rounded-tr-lg rounded-br-lg"
                                                    >
                                                      <div className="flex-shrink-0 rounded-lg inline-flex bg-gray-100 p-2 ring-4 ring-white">
                                                        <img
                                                          className="h-10 w-10"
                                                          src={brand.logo}
                                                          alt=""
                                                        />
                                                      </div>
                                                      <div className="flex-1 min-w-0 ml-4">
                                                        <p className="text-sm font-medium text-gray-900">
                                                          {brand.displayName}
                                                        </p>
                                                        <p className="text-sm text-gray-500 truncate">
                                                          {brand.short_desc}
                                                        </p>
                                                      </div>
                                                    </li>
                                                  </Link>
                                                ))}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {/* EQUIPMENT */}
                                  {category.id === "equipment" && (
                                    <div className="relative bg-white">
                                      <div className="max-w-7xl mx-auto px-8">
                                        <div className="grid grid-cols-2 gap-y-10 gap-x-8 py-16">
                                          <div className="col-start-2 grid grid-cols-2 gap-x-8">
                                            {category.featured.map((item) => (
                                              <div
                                                key={item.name}
                                                className="group relative text-base sm:text-sm"
                                              >
                                                <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden group-hover:opacity-75">
                                                  <img
                                                    src={item.imageSrc}
                                                    alt={item.imageAlt}
                                                    className="object-center object-cover"
                                                  />
                                                </div>
                                                <Link href={item.href} passHref>
                                                  <div className="mt-6 block font-medium text-gray-900">
                                                    <span
                                                      className="absolute z-10 inset-0"
                                                      aria-hidden="true"
                                                    />
                                                    {item.name}
                                                  </div>
                                                </Link>
                                                <p
                                                  aria-hidden="true"
                                                  className="mt-1"
                                                >
                                                  Shop now
                                                </p>
                                              </div>
                                            ))}
                                          </div>
                                          <div className="row-start-1 grid grid-cols-2 text-sm truncate">
                                            <div className="relative rounded-lg bg-white px-6 flex items-center space-x-3 max-w-full">
                                              <ul
                                                role="list"
                                                aria-labelledby={`brand-heading`}
                                                className="space-y-6 sm:space-y-7 w-full"
                                              >
                                                {equipData
                                                  .slice(0, 5)
                                                  .map((item, i) => (
                                                    <li
                                                      className="flow-root"
                                                      key={i}
                                                    >
                                                      <Link href={item.href}>
                                                        <div
                                                          onClick={() => {
                                                            close();
                                                          }}
                                                          className="min-w-full -m-3 p-3 flex items-center space-x-4 rounded-md text-base leading-6 font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
                                                        >
                                                          {item.icon && (
                                                            <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" />
                                                          )}
                                                          {item.svg && item.svg}
                                                          <span className="text-sm font-medium text-gray-900">
                                                            {item.name}
                                                          </span>
                                                        </div>
                                                      </Link>
                                                    </li>
                                                  ))}
                                              </ul>
                                            </div>
                                            <div className="relative rounded-lg bg-white px-6 flex items-center space-x-3 max-w-full">
                                              <ul
                                                role="list"
                                                aria-labelledby={`brand-heading`}
                                                className="space-y-6 sm:space-y-7 w-full"
                                              >
                                                {equipData
                                                  .slice(5, 10)
                                                  .map((item, i) => (
                                                    <li
                                                      className="flow-root"
                                                      key={i}
                                                    >
                                                      <Link href={item.href}>
                                                        <div
                                                          onClick={() => {
                                                            close();
                                                          }}
                                                          className="-m-3 p-3 flex items-center space-x-4 rounded-md text-base leading-6 font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
                                                        >
                                                          {item.icon && (
                                                            <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" />
                                                          )}
                                                          {item.svg && item.svg}
                                                          <span className="text-sm font-medium text-gray-900">
                                                            {item.name}
                                                          </span>
                                                        </div>
                                                      </Link>
                                                    </li>
                                                  ))}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Fragment>
                              )}
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  ))}

                  {navigation.pages.map((page) => (
                    <a
                      key={page.name}
                      href={page.href}
                      className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
                    >
                      {page.name}
                    </a>
                  ))}
                </div>
              </Popover.Group>

              <div className="ml-auto flex items-center">
                {authObject ? (
                  <Fragment>
                    <div className="ml-4 flow-root lg:ml-6">
                      {/* CART */}
                      <div
                        onClick={() => setCartOpen(true)}
                        className="group -m-2 p-2 flex items-center hover:cursor-pointer"
                      >
                        <ShoppingBagIcon
                          className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                          {Boolean(Object.keys(cart).length)
                            ? Object.values(cart).length
                            : 0}
                        </span>
                        <span className="sr-only">items in cart, view bag</span>
                      </div>
                    </div>
                    <div className="flex lg:ml-6 z-10">
                      <UserMenu />
                    </div>
                  </Fragment>
                ) : (
                  <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                    <Link href="/signin">
                      <span className="text-sm font-medium text-gray-700 hover:text-gray-800">
                        Sign in
                      </span>
                    </Link>
                    <span className="h-6 w-px bg-gray-200" aria-hidden="true" />
                    <Link href="/signup">
                      <span className="text-sm font-medium text-gray-700 hover:text-gray-800">
                        Create account
                      </span>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
