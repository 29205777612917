import { Fragment } from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import Cart from "../cart/cart";
import Alert from "../alert/alert";

import { useAlert } from "../../lib/hooks/alert";

const Layout = ({ children }) => {
  const { alert } = useAlert();
  return (
    <Fragment>
      <Header />
      <main>{children}</main>
      <Footer />
      <Cart />
      <Alert type={alert && alert.type} alert={alert} />
    </Fragment>
  );
};

export default Layout;
