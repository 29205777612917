import { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { useAlert } from "../../lib/hooks/alert";

const type = {
  success: {
    icon: (
      <svg
        className="h-5 w-5 text-green-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
    ),
    bg: "bg-green-50",
    title: "text-green-800",
    message: "text-green-700",
    button: "text-green-500",
    hover: "bg-green-100",
    focus: "bg-green-100",
  },
  warning: {
    icon: (
      <svg
        className="h-5 w-5 text-yellow-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
          clipRule="evenodd"
        />
      </svg>
    ),
    bg: "bg-yellow-50",
    title: "text-yellow-800",
    message: "text-yellow-700",
    button: "text-yellow-500",
    hover: "bg-yellow-100",
    focus: "bg-yellow-100",
  },
  error: {
    icon: (
      <svg
        className="h-5 w-5 text-red-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
          clipRule="evenodd"
        />
      </svg>
    ),
    bg: "bg-red-50",
    title: "text-red-800",
    message: "text-red-700",
    button: "text-red-500",
    hover: "bg-red-100",
    focus: "bg-red-100",
  },
  info: {
    icon: (
      <svg
        className="h-5 w-5 text-blue-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clipRule="evenodd"
        />
      </svg>
    ),
    bg: "bg-blue-50",
    title: "text-blue-800",
    message: "text-blue-700",
    button: "text-blue-500",
    hover: "bg-blue-100",
    focus: "bg-blue-100",
  },
};

const Alert = (props) => {
  const [active, setActive] = useState(false);
  const { setAlert, alert } = useAlert();

  useEffect(() => {
    if (props.alert) {
      setActive(true);
    }
    let timer = setTimeout(() => {
      setActive(false);
      setAlert(null);
    }, 6000);
    return () => {
      clearTimeout(timer);
    };
  }, [props.alert]);

  return (
    <div className="fixed w-screen flex justify-center inset-x-0 bottom-0">
      <Transition
        show={active}
        appear
        unmount
        enter="transition linear duration-1000"
        enterFrom="transform translate-y-40"
        enterTo="transform translate-y-0"
        leave="transition linear duration-1000"
        leaveFrom="transform translate-y-0"
        leaveTo="transform translate-y-40"
      >
        {props.type && (
          <div
            className={`rounded-md ${
              type[props.type].bg
            } p-4 z-10 w-11/12 md:w-full mx-auto mb-8 sm:mb-12`}
          >
            <div className="flex items-start">
              <div className="flex-shrink-0 mt-0.5">
                {type[props.type].icon}
              </div>
              <div className="ml-3">
                <h3
                  className={`text-base leading-5 font-medium ${
                    type[props.type].title
                  }`}
                >
                  {props.alert.title}
                </h3>
                <div
                  className={`mt-2 text-base leading-5 ${
                    type[props.type].message
                  }`}
                >
                  <p>{props.alert.message}</p>
                </div>
              </div>
              <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                  <button
                    onClick={() => setActive(false)}
                    className={`inline-flex rounded-md p-1.5 ${
                      type[props.type].button
                    } hover:${
                      type[props.type].hover
                    } focus:outline-none focus:${
                      type[props.type].focus
                    } transition ease-in-out duration-150`}
                    aria-label="Dismiss"
                  >
                    {/* <!-- Heroicon name: x --> */}
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};

export default Alert;
