//Modules
import { Fragment } from "react";
import { useRouter } from "next/router";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { PlusSmIcon, MinusSmIcon } from "@heroicons/react/solid";
import { FireIcon } from "@heroicons/react/outline";

//Hooks
import { useCart } from "../../lib/hooks/cart";
import { useAuth } from "../../lib/hooks/auth";
import { usePrice } from "../../lib/hooks/price";

//Utils
import { getFileExt } from "../../utils/products/utils_products";

export default function Cart() {
  const { cartOpen, setCartOpen, cart, removeItem, increase, decrease } =
    useCart();
  const { getPrice, getSubtotal } = usePrice();
  const { userData } = useAuth();
  const router = useRouter();

  return (
    <Transition.Root show={cartOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-20"
        onClose={setCartOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        Shopping cart
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                          onClick={() => setCartOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>

                    <div className="mt-8">
                      <div className="flow-root">
                        <ul
                          role="list"
                          className="-my-6 divide-y divide-gray-200"
                        >
                          {Boolean(Object.keys(cart).length) ? (
                            <Fragment>
                              {Object.values(cart)
                                // Stablize the list by sorting
                                .sort((a, b) =>
                                  a.eCommerceStoreName > b.eCommerceStoreName
                                    ? 1
                                    : -1
                                )
                                .map((product) => (
                                  <li key={product.SKU} className="py-6 flex">
                                    <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden p-2">
                                      {product.WDCatalogImage ? (
                                        <img
                                          src={`data:image/${getFileExt(
                                            product.WDCatalogImageFileName
                                          )};base64, ${product.WDCatalogImage}`}
                                          alt={product.eCommerceStoreName}
                                          className="w-full h-full object-center object-cover"
                                        />
                                      ) : (
                                        <FireIcon className="w-full h-full object-center object-cover p-4" />
                                      )}
                                    </div>

                                    <div className="ml-4 flex-1 flex flex-col">
                                      <div>
                                        <div className="flex justify-between text-base font-medium text-gray-900">
                                          <h3>
                                            <a href={product.href}>
                                              {product.eCommerceStoreName
                                                ? product.eCommerceStoreName
                                                : "Product"}
                                            </a>
                                          </h3>
                                          <p className="ml-4">
                                            $
                                            {getPrice(
                                              product.SKU,
                                              product.eCommerceCategory,
                                              product.SalesPrice
                                            )}
                                          </p>
                                        </div>
                                        <p className="mt-1 text-sm text-gray-500">
                                          {product.SKU}
                                        </p>
                                      </div>
                                      <div className="flex-1 flex items-end justify-between text-sm">
                                        <div className="flex items-center">
                                          <p className="text-gray-500">
                                            Quantity{" "}
                                            <strong>{product.quantity}</strong>
                                          </p>
                                          <span className="relative inline-flex shadow-sm rounded-md ml-2">
                                            <button
                                              type="button"
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                if (product.quantity > 1) {
                                                  decrease(product.SKU);
                                                }
                                              }}
                                              className="relative inline-flex items-center px-1 py-1 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                            >
                                              <span className="sr-only">
                                                Minus
                                              </span>
                                              <MinusSmIcon
                                                className="h-3 w-3"
                                                aria-hidden="true"
                                              />
                                            </button>
                                            <button
                                              type="button"
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                increase(product.SKU);
                                              }}
                                              className="-ml-px relative inline-flex items-center px-1 py-1 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                            >
                                              <span className="sr-only">
                                                Plus
                                              </span>
                                              <PlusSmIcon
                                                className="h-3 w-3"
                                                aria-hidden="true"
                                              />
                                            </button>
                                          </span>
                                        </div>

                                        <div className="flex">
                                          <button
                                            type="button"
                                            className="font-medium text-indigo-600 hover:text-indigo-500"
                                            onClick={() =>
                                              removeItem(product.SKU)
                                            }
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                            </Fragment>
                          ) : (
                            <div>No items in cart</div>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                    <div className="flex justify-between text-base font-medium text-gray-900">
                      <p>Subtotal</p>
                      <p>${getSubtotal()}</p>
                    </div>
                    <p className="mt-0.5 text-sm text-gray-500">
                      Shipping and discounts calculated at checkout.
                    </p>
                    {/* -----------------------------------*/}
                    {/* ONLY VERIFIED USERS CAN CHECKOUT   */}
                    {/* -----------------------------------*/}
                    {userData?.contact.IsLeadStatusOnly ? (
                      <Fragment>
                        <div className="mt-6">
                          <div className="flex justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600">
                            Checkout disabled - Account not verified.
                          </div>
                        </div>
                        <div className="mt-6 flex justify-center text-sm text-center text-gray-500">
                          <p>
                            <button
                              type="button"
                              className="text-indigo-600 hover:cursor-pointer font-medium hover:text-indigo-500"
                              onClick={() => {
                                router.push("/contact");
                                setCartOpen(false);
                              }}
                            >
                              Contact us to get to verified
                              <span aria-hidden="true"> &rarr;</span>
                            </button>
                          </p>
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="mt-6">
                          <div
                            onClick={() =>
                              Boolean(Object.keys(cart).length) &&
                              router.push("/checkout")
                            }
                            className="flex justify-center items-center px-6 py-3 hover:cursor-pointer border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                          >
                            Checkout
                          </div>
                        </div>
                        <div className="mt-6 flex justify-center text-sm text-center text-gray-500">
                          <p>
                            or{" "}
                            <button
                              type="button"
                              className="text-indigo-600 font-medium hover:text-indigo-500"
                              onClick={() => setCartOpen(false)}
                            >
                              Continue Shopping
                              <span aria-hidden="true"> &rarr;</span>
                            </button>
                          </p>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
