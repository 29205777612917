import {
  DesktopComputerIcon,
  MicrophoneIcon,
  AdjustmentsIcon,
  BadgeCheckIcon,
  ChipIcon,
} from "@heroicons/react/solid";

export const equipment_data = [
  {
    name: "Software",
    href: "/equipment/software",
    icon: DesktopComputerIcon,
  },
  {
    name: "Microphone",
    href: "/equipment/microphone",
    icon: MicrophoneIcon,
  },
  {
    name: "Compressor",
    href: "/equipment/compressor",

    svg: (
      <svg
        className="flex-shrink-0 h-6 w-6 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        stroke="none"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9.21 19H11v3c0 .55.45 1 1 1s1-.45 1-1v-3h1.79c.45 0 .67-.54.35-.85l-2.79-2.79c-.2-.2-.51-.2-.71 0l-2.79 2.79c-.31.31-.09.85.36.85zm5.58-14H13V2c0-.55-.45-1-1-1s-1 .45-1 1v3H9.21c-.45 0-.67.54-.36.85l2.79 2.79c.2.2.51.2.71 0l2.79-2.79c.32-.31.1-.85-.35-.85zM4 12c0 .55.45 1 1 1h14c.55 0 1-.45 1-1s-.45-1-1-1H5c-.55 0-1 .45-1 1z"
        />
      </svg>
    ),
  },
  {
    name: "Equalizer",
    href: "/equipment/equalizer",
    icon: AdjustmentsIcon,
  },
  {
    name: "DI - Instrument Preamp",
    href: "/equipment/di - instrument preamp",
    svg: (
      <svg
        className="flex-shrink-0 h-6 w-6 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        stroke="none"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 14.5c-2.49 0-4.5-2.01-4.5-4.5S9.51 7.5 12 7.5s4.5 2.01 4.5 4.5-2.01 4.5-4.5 4.5zm0-5.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z"
        />
      </svg>
    ),
  },
  {
    name: "Studio Monitor",
    href: "/equipment/studio monitor",
    svg: (
      <svg
        className="flex-shrink-0 h-6 w-6 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        stroke="none"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M17 2H7c-1.1 0-2 .9-2 2v16c0 1.1.9 1.99 2 1.99L17 22c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-5 2c1.1 0 2 .9 2 2s-.9 2-2 2c-1.11 0-2-.9-2-2s.89-2 2-2zm0 16c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
        />
      </svg>
    ),
  },
  {
    name: "Mic Preamp",
    href: "/equipment/mic preamp",
    svg: (
      <svg
        className="flex-shrink-0 h-6 w-6 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        stroke="none"
      >
        <path d="M18.2 1H9.8C8.81 1 8 1.81 8 2.8v14.4c0 .99.81 1.79 1.8 1.79l8.4.01c.99 0 1.8-.81 1.8-1.8V2.8c0-.99-.81-1.8-1.8-1.8zM14 3c1.1 0 2 .89 2 2s-.9 2-2 2-2-.89-2-2 .9-2 2-2zm0 13.5c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z" />
        <circle cx="14" cy="12.5" r="2.5" />
        <path d="M5 5c-.55 0-1 .45-1 1v15c0 1.1.89 2 2 2h9c.55 0 1-.45 1-1s-.45-1-1-1H7c-.55 0-1-.45-1-1V6c0-.55-.45-1-1-1z" />
      </svg>
    ),
  },
  {
    name: "Amplifiers",
    href: "/equipment/amplifiers",
    svg: (
      <svg
        enableBackground="new 0 0 24 24"
        className="flex-shrink-0 h-6 w-6 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        stroke="none"
      >
        <rect fill="none" height="24" width="24" />
        <path d="M6.15,9.97L6.15,9.97C5.69,9.51,5.77,8.73,6.33,8.4C6.73,8.18,7.21,8,7.75,8c0.8,0,1.39,0.39,1.81,0.67 C9.87,8.88,10.07,9,10.25,9c0.13,0,0.26-0.05,0.39-0.12c0.39-0.22,0.88-0.16,1.2,0.16l0,0c0.46,0.46,0.38,1.24-0.18,1.56 c-0.39,0.23-0.87,0.4-1.41,0.4c-0.79,0-1.37-0.38-1.79-0.66C8.13,10.12,7.94,10,7.75,10c-0.13,0-0.26,0.05-0.39,0.12 C6.96,10.35,6.47,10.28,6.15,9.97z M7.75,15c0.19,0,0.38,0.12,0.71,0.34c0.42,0.28,1,0.66,1.79,0.66c0.54,0,1.02-0.17,1.41-0.4 c0.56-0.32,0.64-1.1,0.18-1.56l0,0c-0.32-0.32-0.81-0.38-1.2-0.16C10.51,13.95,10.38,14,10.25,14c-0.18,0-0.38-0.12-0.69-0.33 C9.14,13.39,8.55,13,7.75,13c-0.54,0-1.02,0.18-1.42,0.4c-0.56,0.33-0.64,1.11-0.18,1.56l0,0c0.32,0.32,0.81,0.38,1.2,0.16 C7.49,15.05,7.62,15,7.75,15z M22,6v12c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V6c0-1.1,0.9-2,2-2h16C21.1,4,22,4.9,22,6z M14,6H4v12h10 V6z M19,16c0-0.55-0.45-1-1-1c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1C18.55,17,19,16.55,19,16z M19,12c0-0.55-0.45-1-1-1 c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1C18.55,13,19,12.55,19,12z M19,7h-2v2h2V7z" />
      </svg>
    ),
  },
  {
    name: "Signal Processors",
    href: "/equipment/signal processors",
    icon: ChipIcon,
  },
  {
    name: "Merch",
    href: "/equipment/merch",
    svg: (
      <svg
        className="flex-shrink-0 h-6 w-6 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        stroke="none"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M17.09 4.56c-.7-1.03-1.5-1.99-2.4-2.85-.35-.34-.94-.02-.84.46.19.94.39 2.18.39 3.29 0 2.06-1.35 3.73-3.41 3.73-1.54 0-2.8-.93-3.35-2.26-.1-.2-.14-.32-.2-.54-.11-.42-.66-.55-.9-.18-.18.27-.35.54-.51.83C4.68 9.08 4 11.46 4 14c0 4.42 3.58 8 8 8s8-3.58 8-8c0-3.49-1.08-6.73-2.91-9.44zM11.71 19c-1.78 0-3.22-1.4-3.22-3.14 0-1.62 1.05-2.76 2.81-3.12 1.47-.3 2.98-.93 4.03-1.92.28-.26.74-.14.82.23.23 1.02.35 2.08.35 3.15.01 2.65-2.14 4.8-4.79 4.8z" />
      </svg>
    ),
  },
];
